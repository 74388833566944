<template>
  <b-card>
    <b-card-title>
      Genel Bilgi
    </b-card-title>
    <b-row>
      <b-col cols="12">
        <company />
      </b-col>
      <b-col cols="12">
        <name />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <phone />
      </b-col>
      <b-col cols="12">
        <phone-alert />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <phone2 />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <fax />
      </b-col>
      <b-col cols="12">
        <email />
      </b-col>
      <b-col cols="12">
        <brands />
      </b-col>
      <b-col
        cols="12"
      >
        <notes />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle,
} from 'bootstrap-vue'
import Company from '@/views/Admin/Dealers/elements/Company.vue'
import Name from '@/views/Admin/Dealers/elements/Name.vue'
import Phone from '@/views/Admin/Dealers/elements/Phone.vue'
import PhoneAlert from '@/views/Admin/Dealers/elements/PhoneAlert.vue'
import Phone2 from '@/views/Admin/Dealers/elements/Phone2.vue'
import Fax from '@/views/Admin/Dealers/elements/Fax.vue'
import Email from '@/views/Admin/Dealers/elements/Email.vue'
import Notes from '@/views/Admin/Dealers/elements/Notes.vue'
import Brands from '@/views/Admin/Dealers/elements/Brands.vue'

export default {
  name: 'DealerForm',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    Company,
    Name,
    Phone,
    PhoneAlert,
    Phone2,
    Fax,
    Email,
    Notes,
    Brands,
  },
}
</script>
