<template>
  <b-alert
    v-if="phoneControl.status"
    class="mt-1 pt-1"
    variant="primary"
    show
  >
    <div class="alert-body text-center">
      <FeatherIcon icon="InfoIcon" />
      <div class="pb-1">
        Bu telefon numarası başka bir bayi kartında kullanılmaktadır.
      </div>
      <div class="pb-1">
        <b-button
          variant="primary"
          :to="'/app/dealers/view/' + phoneControl.id"
          size="sm"
        >
          Bayi Kartı Görüntüle
        </b-button>
      </div>
    </div>
  </b-alert>
</template>

<script>
import { BAlert, BButton } from 'bootstrap-vue'

export default {
  name: 'PhoneAlert',
  components: {
    BAlert, BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealers/dataItem']
    },
    phoneControl() {
      return this.$store.getters['dealers/phoneControl']
    },
  },
}
</script>
