<template>
  <div>
    <b-form-group
      label="Markalar"
      label-for="brands"
    >
      <validation-provider
        #default="{ errors }"
        name="Markalar"
        rules="required"
      >
        <v-select
          id="brands"
          v-model="dataItem.brands"
          :options="brands"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
          multiple
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Merkez Veri Görüntüleme Yetkileri"
      label-for="permissions"
    >
      <b-row>
        <b-col>
          <b-form-checkbox
            v-model="dataItem.permission_customers"
            class="mb-1"
            value="1"
          >
            Müşteri Kartları
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="dataItem.permission_interviews"
            class="mb-1"
            value="1"
          >
            Görüşmeler
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="dataItem.permission_offers"
            class="mb-1"
            value="1"
          >
            Teklifler
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="dataItem.permission_proforma_invoices"
            class="mb-1"
            value="1"
          >
            Proforma Faturalar
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="dataItem.permission_services"
            class="mb-1"
            value="1"
          >
            Servis Formları
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form-group>
    <b-alert
      show
      variant="danger"
    >
      <div class="alert-heading">
        Merkez Veri Görüntüleme Yetkileri
      </div>
      <div class="alert-body">
        <p>
          Seçili Marka / Markalar için Mert Dijital'e ait izin verilen kayıtları görüntüleyebilecektir.
        </p>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BAlert, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    BAlert,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealers/dataItem']
    },
    brands() {
      return this.$store.getters['brands/dataList']
    },
  },
  created() {
    localize('tr')
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/getDataList', {
        select: [
          'brands.id AS id',
          'brands.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
