<template>
  <div>
    <public />
    <billing-information />
  </div>
</template>

<script>
import Public from '@/views/Admin/Dealers/DealerForm/Public.vue'
import BillingInformation from '@/views/Admin/Dealers/DealerForm/BillingInformation.vue'

export default {
  name: 'DealerForm',
  components: {
    Public,
    BillingInformation,
  },
}
</script>
