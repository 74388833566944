<template>
  <b-form-group
    label="Vergi No"
    label-for="tax_number"
  >
    <b-form-input
      id="tax_number"
      v-model="dataItem.tax_number"
      placeholder="Vergi No"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'TaxNumber',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealers/dataItem']
    },
  },
}
</script>
